<template>
  <div class="register">
    <v-container class="my-6">
      <v-row class="text-center">
        <v-col>
          <h1>Registrar cuenta</h1>
        </v-col>
      </v-row>
      <v-form
        v-model="formProps.valid"
        ref="form"
        lazy-validation
        v-on:submit.prevent
      >
        <v-row class="justify-center">
          <v-col cols="6" md="4">
            <v-text-field
              label="Nombres y Apellidos"
              v-model="formData.name"
              :rules="formProps.nameRules"
              name="name"
              id="name"
              autocomplete="name"
              required
              ref="name"
            />
            <v-text-field
              label="Email"
              v-model="formData.email"
              :rules="formProps.emailRules"
              name="email"
              id="email"
              autocomplete="email"
              required
              ref="email"
            />
            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              label="Contraseña"
              v-model="formData.password"
              :rules="[
                formProps.passRules.required,
                formProps.passRules.length,
                passwordConfirmationRule
              ]"
              :type="show ? 'text' : 'password'"
              name="password"
              id="password"
              autocomplete="password"
              required
              ref="password"
              @click:append="show = !show"
            />
            <v-text-field
              :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
              label="Repita la contraseña"
              v-model="formData.password_confirmation"
              :rules="[
                formProps.passConfirmationRules.required,
                formProps.passRules.length,
                passwordConfirmationRule
              ]"
              :type="showConfirmation ? 'text' : 'password'"
              name="password_confirmation"
              id="password_confirmation"
              autocomplete="password_confirmation"
              required
              ref="password"
              @click:append="showConfirmation = !showConfirmation"
            />
          </v-col>
        </v-row>

        <v-row class="justify-center my-5">
          <v-col cols="6" md="2">
            <v-btn
              :loading="loading"
              :disabled="!formProps.valid || loading"
              @click="submit"
              color="primary"
              large
              type="submit"
              block
            >
              Registrar
              <template v-slot:loader>
                <span color="info">Registrando...</span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    show: false,
    showConfirmation: false,
    formData: {
      email: '',
      password: '',
      name: '',
      password_confirmation: ''
    },
    formProps: {
      valid: true,
      nameRules: [
        v => !!v || 'Ingrese su Nombre y Apellidos',
        v => /.+\s.+/.test(v) || 'Al menos 2 palabras'
      ],
      emailRules: [
        v => !!v || 'Ingrese su correo',
        v => /.+@.+\..+/.test(v) || 'Escriba un correo válido'
      ],
      passRules: {
        required: v => !!v || 'Ingrese su contraseña',
        length: v =>
          (v && v.length >= 8) || 'La clave debe tener al menos 8 caracteres'
      },
      passConfirmationRules: {
        required: v => !!v || 'Ingrese su contraseña nuevamente'
      }
    },
    loading: false
  }),
  methods: {
    submit: async function() {
      if (this.$refs.form.validate()) {
        console.log(this.formData)
        this.loading = true
        let register_result = await this.$store.dispatch(
          'register',
          this.formData
        )
        if (register_result) return this.$router.push('home')
        this.loading = false
      }
    }
  },
  computed: {
    passwordConfirmationRule() {
      let confirmation =
        ((!!this.formData.password_confirmation || !!this.formData.password) &&
          this.formData.password === this.formData.password_confirmation) ||
        'Las contraseñas deben coincidir'
      return confirmation
    }
  }
}
</script>
