var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('v-container',{staticClass:"my-6"},[_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('h1',[_vm._v("Registrar cuenta")])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.formProps.valid),callback:function ($$v) {_vm.$set(_vm.formProps, "valid", $$v)},expression:"formProps.valid"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-text-field',{ref:"name",attrs:{"label":"Nombres y Apellidos","rules":_vm.formProps.nameRules,"name":"name","id":"name","autocomplete":"name","required":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('v-text-field',{ref:"email",attrs:{"label":"Email","rules":_vm.formProps.emailRules,"name":"email","id":"email","autocomplete":"email","required":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('v-text-field',{ref:"password",attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"label":"Contraseña","rules":[
              _vm.formProps.passRules.required,
              _vm.formProps.passRules.length,
              _vm.passwordConfirmationRule
            ],"type":_vm.show ? 'text' : 'password',"name":"password","id":"password","autocomplete":"password","required":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('v-text-field',{ref:"password",attrs:{"append-icon":_vm.showConfirmation ? 'mdi-eye' : 'mdi-eye-off',"label":"Repita la contraseña","rules":[
              _vm.formProps.passConfirmationRules.required,
              _vm.formProps.passRules.length,
              _vm.passwordConfirmationRule
            ],"type":_vm.showConfirmation ? 'text' : 'password',"name":"password_confirmation","id":"password_confirmation","autocomplete":"password_confirmation","required":""},on:{"click:append":function($event){_vm.showConfirmation = !_vm.showConfirmation}},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", $$v)},expression:"formData.password_confirmation"}})],1)],1),_c('v-row',{staticClass:"justify-center my-5"},[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":!_vm.formProps.valid || _vm.loading,"color":"primary","large":"","type":"submit","block":""},on:{"click":_vm.submit},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{attrs:{"color":"info"}},[_vm._v("Registrando...")]),_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_vm._v(" Registrar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }